<template>
  <div>
    <p class="is-size-7 has-text-weight-light">
      {{ getDate(event) }}
    </p>
    <p class="title is-size-5 has-text-dark">First registration date</p>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'TimelineRegistered',
  props: {
    event: {
      type: String,
      required: true
    }
  },
  methods: {
    getDate(value) {
      return formatDate(value, 'do MMMM yyyy')
    }
  }
}
</script>
